import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { graphql, Link } from 'gatsby'
import Img from 'gatsby-image'
import * as _ from 'lodash'
import { lighten, setLightness } from 'polished'
import React, { useState } from 'react'
import { Header, SectionCta, SectionFeatures } from '../components'
import AzLayout from '../components/AzLayout'
import Footer from '../components/Footer'
import PostContent from '../components/PostContent'
import SectionHero from '../components/SectionHero'
import SectionClearText from '../components/SectionClearText'
import SectionContacts from '../components/SectionContacts'
import SectionFooter from '../components/SectionFooter'
import SectionMosaic from '../components/SectionMosaic'
import SectionNewsletter from '../components/SectionNewsletter'
import SectionRecentPosts from '../components/SectionRecentPosts'
import SectionSocialMedia from '../components/SectionSocialMedia'
import { colors } from '../styles/colors'
import { inner, outer, SiteMain } from '../styles/shared'
import SectionImageText from '../components/SectionImageText'

const themes = ['main', 'alt']

const PageTemplate = (props) => {
    const [admin, setAdmin] = useState(false)

    let isAdmin = admin

    const isEditing = props.data.allContentfulGlobalSettings.edges[0].node.editing
    if (isEditing) isAdmin = true

    const theme = themes[0]
    const { data, pageContext, location } = props
    const post = data.contentfulBlogPost

    let width = ''
    let height = ''

    if (post.image?.childImageSharp) {
        width = post.image.childImageSharp.fluid.sizes.split(', ')[1].split('px')[0]
        height = String(Number(width) / post.image.childImageSharp.fluid.aspectRatio)
    }

    const date = post.date
    // 2018-08-20
    const datetime = date //format(date, 'yyyy-MM-dd')
    // 20 AUG 2018
    const displayDatetime = date //format(date, 'dd LLL yyyy')

    let header = {}
    let footer = {}
    if (props.data.allContentfulNavigation) {
        for (const edge of props.data.allContentfulNavigation.edges) {
            const block = edge.node
            if (block.links && block.links.length > 0 && block.links[0].node_locale === props.pageContext.language) {
                if (block.type === 'header') {
                    header = block
                }
                if (block.type === 'footer') {
                    footer = block
                }
            }
        }
    }

    header.data = { site: props.data.site, url: props.data.url }
    footer.data = { site: props.data.site, url: props.data.url }

    const content = []
    let index = 0
    if (props.data.allContentfulBlogFooter.edges[0].node.footerElements) {
        for (const block of props.data.allContentfulBlogFooter.edges[0].node.footerElements) {
            if (!block.internal || block.hidden) continue
            const type = block.internal.type
            switch (type) {
                case 'ContentfulAuthor':
                    content.push(
                        <div>
                            <SectionCta isAdmin={isAdmin} key={index} {...{ ...props }} block={block} theme={theme} />
                        </div>
                    )
                    break
                case 'ContentfulMosaic':
                    content.push(
                        <div>
                            <SectionMosaic isAdmin={isAdmin} key={index} {...{ ...props, section_id: 'mosaic' }} block={block} theme={theme} />
                        </div>
                    )
                    break
                case 'ContentfulHero':
                    content.push(
                        <div>
                            <SectionHero isAdmin={isAdmin} key={index} {...{ ...props, section_id: 'hero' }} block={block} theme={theme} />
                        </div>
                    )
                    break
                case 'ContentfulClearText':
                    content.push(
                        <div>
                            <SectionClearText isAdmin={isAdmin} key={index} {...{ ...props }} block={block} theme={theme} />
                        </div>
                    )
                    break
                case 'ContentfulRecentPosts':
                    content.push(
                        <div>
                            <SectionRecentPosts isAdmin={isAdmin} key={index} {...{ ...props }} block={block} theme={theme} />
                        </div>
                    )
                    break
                case 'ContentfulNewsletter':
                    content.push(
                        <div>
                            <SectionNewsletter isAdmin={isAdmin} key={index} {...{ ...props }} block={block} theme={theme} />
                        </div>
                    )
                    break
                case 'ContentfulContacts':
                    content.push(
                        <div>
                            <SectionContacts isAdmin={isAdmin} key={index} {...{ ...props }} block={block} theme={theme} />
                        </div>
                    )
                    break
                case 'ContentfulSocialMedia':
                    content.push(
                        <div>
                            <SectionSocialMedia isAdmin={isAdmin} key={index} {...{ ...props }} block={block} theme={theme} />
                        </div>
                    )
                    break
                case 'ContentfulFooter':
                    content.push(
                        <div>
                            <SectionFooter isAdmin={isAdmin} key={index} {...{ ...props }} block={block} theme={theme} />
                        </div>
                    )
                    break
                case 'ContentfulFeatures':
                    content.push(
                        <SectionFeatures key={index} {...{ ...props, section_id: 'features', features: [block] }} block={block} theme={theme} />
                    )
                    break
                case 'ContentfulNavigation':
                    if (block.type === 'footer') {
                        content.push(<Footer isAdmin={isAdmin} key={index} data={props.data} {...block} block={block} theme={theme} />)
                    }
                default:
                    break
            }
            index++
        }
    }

    return (
        <AzLayout {...props}>
            <Header isAdmin={isAdmin} {...header} />
            <SectionHero isAdmin={isAdmin} {...{ ...props, section_id: 'hero' }} block={{ ...post, body: null }} theme={theme} />

            <SectionImageText
                isAdmin={isAdmin}
                {...{ ...props }}
                block={{
                    ...post,
                    title: post.subtitle,
                    body: post.description,
                    image: post.firstImage,
                }}
                theme={theme}
            />

            <SectionClearText
                isAdmin={isAdmin}
                {...{ ...props }}
                block={{
                    ...post,
                }}
                theme={theme}
            />

            {/* <ReadNext currentPageSlug={location.pathname} tags={post.tags} relatedPosts={data.relatedPosts} pageContext={pageContext} /> */}
            {content}
        </AzLayout>
    )
}

const PostTemplate = css`
    .site-main {
        margin-top: 64px;
        background: #fff;
        padding-bottom: 4vw;
    }

    @media (prefers-color-scheme: dark) {
        .site-main {
            /* background: var(--darkmode); */
            background: ${colors.darkmode};
        }
    }
`

export const PostFull = css`
    position: relative;
    z-index: 50;
`

export const NoImage = css`
    .post-full-content {
        padding-top: 0;
    }

    .post-full-content:before,
    .post-full-content:after {
        display: none;
    }
`

export const PostFullHeader = styled.header`
    position: relative;
    margin: 0 auto;
    padding: 70px 170px 50px;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;

    @media (max-width: 1170px) {
        padding: 60px 11vw 50px;
    }

    @media (max-width: 800px) {
        padding-right: 5vw;
        padding-left: 5vw;
    }

    @media (max-width: 500px) {
        padding: 20px 0 35px;
    }
`

const PostFullTags = styled.section`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    /* color: var(--midgrey); */
    color: ${colors.midgrey};
    font-size: 1.3rem;
    line-height: 1.4em;
    font-weight: 600;
    text-transform: uppercase;
`

const PostFullCustomExcerpt = styled.p`
    margin: 20px 0 0;
    color: var(--midgrey);
    font-family: Georgia, serif;
    font-size: 2.3rem;
    line-height: 1.4em;
    font-weight: 300;

    @media (max-width: 500px) {
        font-size: 1.9rem;
        line-height: 1.5em;
    }

    @media (prefers-color-scheme: dark) {
        /* color: color(var(--midgrey) l(+10%)); */
        color: ${lighten('0.1', colors.midgrey)};
    }
`

const PostFullByline = styled.div`
    display: flex;
    justify-content: space-between;
    margin: 35px 0 0;
    padding-top: 15px;
    /* border-top: 1px solid color(var(--lightgrey) l(+10%)); */
    border-top: 1px solid ${lighten('0.1', colors.lightgrey)};

    .post-full-byline-content {
        flex-grow: 1;
        display: flex;
        align-items: flex-start;
    }

    .post-full-byline-content .author-list {
        justify-content: flex-start;
        padding: 0 12px 0 0;
    }

    .post-full-byline-meta {
        margin: 2px 0 0;
        /* color: color(var(--midgrey) l(+10%)); */
        color: ${lighten('0.1', colors.midgrey)};
        font-size: 1.2rem;
        line-height: 1.2em;
        letter-spacing: 0.2px;
        text-transform: uppercase;
    }

    .post-full-byline-meta h4 {
        margin: 0 0 3px;
        font-size: 1.3rem;
        line-height: 1.4em;
        font-weight: 500;
    }

    .post-full-byline-meta h4 a {
        /* color: color(var(--darkgrey) l(+10%)); */
        color: ${lighten('0.1', colors.darkgrey)};
    }

    .post-full-byline-meta h4 a:hover {
        /* color: var(--darkgrey); */
        color: ${colors.darkgrey};
    }

    .post-full-byline-meta .bull {
        display: inline-block;
        margin: 0 4px;
        opacity: 0.6;
    }

    @media (prefers-color-scheme: dark) {
        /* border-top-color: color(var(--darkmode) l(+15%)); */
        border-top-color: ${lighten('0.15', colors.darkmode)};

        .post-full-byline-meta h4 a {
            color: rgba(255, 255, 255, 0.75);
        }

        .post-full-byline-meta h4 a:hover {
            color: #fff;
        }
    }
`

export const PostFullTitle = styled.h1`
    margin: 0 0 0.2em;
    color: ${setLightness('0.05', colors.darkgrey)};
    @media (max-width: 500px) {
        margin-top: 0.2em;
        font-size: 3.3rem;
    }

    @media (prefers-color-scheme: dark) {
        color: rgba(255, 255, 255, 0.9);
    }
`

const PostFullImage = styled.figure`
    margin: 25px 0 50px;
    height: 800px;
    background: ${colors.lightgrey} center center;
    background-size: cover;
    border-radius: 5px;

    @media (max-width: 1170px) {
        margin: 25px -6vw 50px;
        border-radius: 0;
        img {
            max-width: 1170px;
        }
    }

    @media (max-width: 800px) {
        height: 400px;
    }
    @media (max-width: 500px) {
        margin-bottom: 4vw;
        height: 350px;
    }
`

// hero {
//   fluid(maxWidth: 1180, background: "rgb:000000") {
//       ...GatsbyContentfulFluid
//   }
// }
export const query = graphql`
    query($locale: String, $slug: String) {
        logo: file(relativePath: { eq: "img/ghost-logo.png" }) {
            childImageSharp {
                fixed {
                    ...GatsbyImageSharpFixed
                }
            }
        }
        allContentfulGlobalSettings {
            edges {
                node {
                    contentful_id
                    id
                    editing
                }
            }
        }
        allContentfulBlogFooter {
            edges {
                node {
                    contentful_id
                    id
                    title
                    footerElements {
                        ... on ContentfulNewsletter {
                            contentful_id
                            id
                            title
                            createdAt
                            updatedAt
                            internal {
                                type
                            }
                            body {
                                id
                                childMarkdownRemark {
                                    id
                                    html
                                }
                            }
                            placeholder
                            subscribe
                            heroImage {
                                fluid(maxWidth: 1180, background: "rgb:000000") {
                                    ...GatsbyContentfulFluid
                                }
                            }
                            hidden
                        }
                        ... on ContentfulNavigation {
                            contentful_id
                            id
                            type
                            node_locale
                            logo {
                                fluid(maxWidth: 1180, background: "rgb:000000") {
                                    ...GatsbyContentfulFluid
                                }
                            }
                            links {
                                id
                                title
                                node_locale
                                shortName
                                slug
                                navButtonType
                            }
                            title
                            internal {
                                type
                            }
                        }
                        ... on ContentfulSocialMedia {
                            contentful_id
                            id
                            title
                            facebook
                            linkedin
                            twitter
                            instagram
                            createdAt
                            updatedAt
                            internal {
                                type
                            }
                        }
                        ... on ContentfulContacts {
                            contentful_id
                            id
                            title
                            telephone
                            email
                            address
                            createdAt
                            updatedAt
                            internal {
                                type
                            }
                        }
                        ... on ContentfulFooter {
                            contentful_id
                            id
                            footerText {
                                id
                                childMarkdownRemark {
                                    id
                                    html
                                }
                            }
                            createdAt
                            updatedAt
                            internal {
                                type
                            }
                        }
                    }
                }
            }
        }
        allContentfulNavigation {
            edges {
                node {
                    contentful_id
                    id
                    type
                    node_locale
                    links {
                        id
                        title
                        node_locale
                        shortName
                        slug
                        navButtonType
                    }
                    logo {
                        fluid(maxWidth: 1180, background: "rgb:000000") {
                            ...GatsbyContentfulFluid
                        }
                    }
                }
            }
        }
        contentfulBlogPost(node_locale: { eq: $locale }, slug: { eq: $slug }) {
            contentful_id
            title
            shortName
            slug
            firstImage {
                fluid(maxWidth: 1180, background: "rgb:000000") {
                    ...GatsbyContentfulFluid
                }
            }
            separator {
                fluid(maxWidth: 1180, background: "rgb:000000") {
                    ...GatsbyContentfulFluid
                }
            }
            separatorExtra {
                fluid(maxWidth: 1180, background: "rgb:000000") {
                    ...GatsbyContentfulFluid
                }
            }
            heroImage {
                fluid(maxWidth: 1180, background: "rgb:000000") {
                    ...GatsbyContentfulFluid
                }
            }
            subtitle
            description {
                id
                childMarkdownRemark {
                    id
                    html
                }
            }
            body {
                id
                childMarkdownRemark {
                    id
                    html
                }
            }
            author {
                contentful_id
                id
                name
                title
                company
                shortBio {
                    id
                    childMarkdownRemark {
                        id
                        html
                    }
                }
                email
                phone
                facebook
                twitter
                github
                image {
                    fluid(maxWidth: 1180, background: "rgb:000000") {
                        ...GatsbyContentfulFluid
                    }
                }
            }
            publishDate(formatString: "MMMM Do, YYYY")
            tags
        }
        site {
            siteMetadata {
                title
                description
                base_font
                site_url
                footer {
                    content
                    has_nav
                    nav_links {
                        ... on SiteSiteMetadataFooterNav_links {
                            label
                            style
                            url
                        }
                    }
                    sections {
                        ... on SiteSiteMetadataFooterSections {
                            content
                            form_fields {
                                ... on SiteSiteMetadataFooterSectionsForm_fields {
                                    default_value
                                    input_type
                                    is_required
                                    label
                                    name
                                }
                            }
                            form_id
                            hide_labels
                            image
                            image_alt
                            image_url
                            nav_links {
                                ... on SiteSiteMetadataFooterSectionsNav_links {
                                    label
                                    style
                                    url
                                }
                            }
                            submit_label
                            title
                            type
                        }
                    }
                }
                header {
                    has_nav
                    logo_img
                    logo_img_alt
                    nav_links {
                        ... on SiteSiteMetadataHeaderNav_links {
                            label
                            style
                            url
                        }
                    }
                }
                palette
            }
        }
    }
`

// contentfulBlogPost(fields: { slug: { eq: $slug } }) {
//   html
//   htmlAst
//   excerpt
//   timeToRead
//   frontmatter {
//       title
//       userDate: date(formatString: "D MMMM YYYY")
//       date
//       tags
//       excerpt
//       image {
//           childImageSharp {
//               fluid(maxWidth: 3720) {
//                   ...GatsbyImageSharpFluid
//               }
//           }
//       }
//       author {
//           id
//           bio
//           avatar {
//               children {
//                   ... on ImageSharp {
//                       fluid(quality: 100, srcSetBreakpoints: [40, 80, 120]) {
//                           ...GatsbyImageSharpFluid
//                       }
//                   }
//               }
//           }
//       }
//   }
// }
// relatedPosts: allcontentfulBlogPost(
//   filter: { frontmatter: { tags: { in: [$primaryTag] }, draft: { ne: true } } }
//   limit: 5
//   sort: { fields: [frontmatter___date], order: DESC }
// ) {
//   totalCount
//   edges {
//       node {
//           id
//           timeToRead
//           excerpt
//           frontmatter {
//               title
//               date
//           }
//           fields {
//               slug
//           }
//       }
//   }
// }

export default PageTemplate
